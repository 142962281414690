import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Hero from './components/hero/hero';
import Features from './components/features/features';
import WhyChoose from './components/whyChooseUs/whyChooseUs';
//import Testimonials from './components//testimonals/testimonials';
import Pricing from './components/pricing/pricing';
import Contact from './components/contact/contact';
import AboutUs from './components/aboutUs/aboutUs';  // Import the About Us component
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        
        <Routes>
          {/* Home page */}
          <Route 
            path="/" 
            element={
              <>
                <Hero />
                <Features />
                <WhyChoose />
                <Pricing />
                <Contact />
              </>
            } 
          />

          {/* About Us page */}
          <Route 
            path="/about" 
            element={<AboutUs />} 
          />
        </Routes>
        
        <Footer />
      </div>
    </Router>
  );
}

export default App;
