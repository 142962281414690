import React from 'react';
import './whyChooseUs.css';

const WhyChoose = () => {
  return (
    <section className="why-choose" id="about">
      <h2>Why Choose Clinix Sphere?</h2><br />
      <div className="why-choose-cards">
        <div className="why-choose-card">
          <h3>Streamlined Patient Management</h3>
          <ul>
            <li>Simplifies patient records, appointment scheduling, and medical history tracking.</li>
            <li>Provides a seamless platform for efficient management of every patient interaction.</li>
          </ul>
        </div>
        <div className="why-choose-card">
          <h3>Efficient Inventory Management</h3>
          <ul>
            <li>Real-time tracking of medical supplies and equipment.</li>
            <li>Minimizes shortages and enhances operational efficiency.</li>
          </ul>
        </div>
        <div className="why-choose-card">
          <h3>Comprehensive Metrics and Reporting</h3>
          <ul>
            <li>Offers detailed insights into clinic performance, patient trends, and financial metrics.</li>
            <li>Empowers healthcare providers to make data-driven decisions.</li>
          </ul>
        </div>
        <div className="why-choose-card">
          <h3>Priority Support for Hassle-Free Operations</h3>
          <ul>
            <li>Guarantees timely assistance with priority support.</li>
            <li>Minimizes downtime and ensures smooth clinic operations.</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default WhyChoose;
