import React from 'react';
import './features.css';

const Features = () => {
  return (
    <section className="features" id="features">
      <h2>Key Features</h2><br />
      <div className="feature-cards">
        <div className="feature-card">
          <div className="feature-card-content">
            <h3>Seamless Patient Management</h3>
            <p>Effortlessly manage patient records, appointments, and medical history in a unified system.</p>
          </div>
        </div>
        <div className="feature-card">
          <div className="feature-card-content">
            <h3>Automated Billing and Secure Payments</h3>
            <p>Generate detailed invoices and offer secure, hassle-free online payment options.</p>
          </div>
        </div>
        <div className="feature-card">
          <div className="feature-card-content">
            <h3>Real-Time Inventory Management</h3>
            <p>Monitor medical supplies and equipment with live updates to prevent shortages.</p>
          </div>
        </div>
        <div className="feature-card">
          <div className="feature-card-content">
            <h3>Advanced and Powerful Reporting & Analytics</h3>
            <p>Gain valuable insights into clinic performance with comprehensive data and metrics.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
