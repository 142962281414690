import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './header.css';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);// State to manage menu visibility
  const navRef = useRef(null); // Ref to detect clicks outside the nav

  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);// Toggle menu open/close state
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsMenuOpen(false);// Close the menu if clicked outside
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);// Listen for mouse clicks outside

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className={`header ${scrolled ? 'scrolled' : ''}`}>
      <img src='/clinixsphere_logo.png' alt='LOGO' className='logo' />
      {/* Animated Hamburger Menu for Mobile View */}

      <div
        className={`menu-toggle ${isMenuOpen ? 'open' : ''}`}
        onClick={handleMenuToggle}
      >
        <span></span>
      </div>
      {/* Navigation bar 
      // Reference to detect outside clicks
      */}

      <nav className={`nav ${isMenuOpen ? 'active' : ''}`} ref={navRef}>
        <Link to="/" onClick={() => setIsMenuOpen(false)}>Home</Link>
        <Link to="/about" onClick={() => setIsMenuOpen(false)}>About Us</Link>
        <Link to="/" onClick={() => setIsMenuOpen(false)}>Features</Link>
        <Link to="/" onClick={() => setIsMenuOpen(false)}>Pricing</Link>
        <Link to="/" onClick={() => setIsMenuOpen(false)}>Contact</Link>
        <Link to="/" onClick={() => setIsMenuOpen(false)}>Blog</Link>
      </nav>
    </header>
  );
};

export default Header;
