import React, { useState , useEffect} from 'react';

import { saveContact } from '../../api/contactApi'; // Import the API function
import Popup from '../common/popup';
import './hero.css';

const Hero = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        // Listen for scroll events to determine if the page is scrolled
        const handleScroll = () => {
            if (window.scrollY > 50) { // Adjust the value if needed
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleRequestDemoClick = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleSubmitForm = async (formData) => {       

        // API request to save the contact details using the API function
        try {
            const data = await saveContact(formData);  // Use API function
            console.log('Contact saved successfully:', data);
            showToast('Your message has been sent successfully!', 'success');

            setIsPopupOpen(false); // Close the popup on successful submit
        } catch (error) {
            showToast('There was an error sending your message. Please try again.', 'error');

            console.error('Error saving contact:', error);
        }
    };
    const showToast = (message, type) => {
        // Create the toast element
        const toast = document.createElement('div');
        toast.className = `toast-card ${type}`;
        toast.innerText = message;
    // Change the background color of the toast if the page is not scrolled (header in #388087)
    if (!isScrolled && type === 'success') {
        toast.style.backgroundColor = '#ffffff';
        toast.style.color = '#388087' // Set a different color to avoid blending with the header
    }

         // Create the progress bar element
         const progressBar = document.createElement('div');
         progressBar.className = 'toast-progress';
     
         // Append the progress bar to the toast
         toast.appendChild(progressBar);
     
        // Add the toast to the body or container
        document.body.appendChild(toast);

        // Set the toast to disappear after 6 seconds
        setTimeout(() => {
            toast.classList.add('fade-out');  // Add a fade-out effect before removing it
            setTimeout(() => {
                toast.remove();  // Remove the toast after the fade-out effect
            }, 500);  // Time for the fade-out effect
        }, 5000);  // Duration of the toast display
    };


    const fields = [
        { name: 'name', label: 'Name', type: 'text' },
        {
            name: 'phone',
            label: 'Phone',
            type: 'tel',
            pattern: '^[0-9]{10}$',
            customErrorMessage: 'Please enter a 10 digit phone number' // Custom message
        },
        {
            name: 'email',
            label: 'Email',
            type: 'email',
            pattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$', // Email validation pattern
            customErrorMessage: 'Please provide a valid email Id' // Custom message for email
        },
        { name: 'query', label: 'Query', type: 'textarea' },
    ];

    const requiredFields = ['name', 'phone'];

    return (
        <div className="hero">
            <div className="hero-content">
                <h1 className="hero-heading">Empowering Healthcare with Trustworthy & Professional Solutions</h1>
                <p className="hero-subheading">
                    Clinix Sphere helps clinics and hospitals manage patients, appointments, and billing with ease, providing a streamlined experience from start to finish.
                </p>
                <div className="cta-buttons">
                    <button className="primary-cta" onClick={handleRequestDemoClick}>Request a Demo</button>
                    <button className="secondary-cta">Learn More</button>
                </div>
            </div>

            {/* Popup Component */}
            <Popup
                isOpen={isPopupOpen}
                onClose={handleClosePopup}
                title="Request a Demo"
                fields={fields}
                requiredFields={requiredFields}
                onSubmit={handleSubmitForm}
            />
        </div>
    );
};

export default Hero;
