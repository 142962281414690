import React from 'react';
import { saveContact } from '../../api/contactApi';
import './contact.css';  // Add styles for the toast in the CSS file

const Contact = () => {
    
    const handleSubmitForm = async (event) => {
        event.preventDefault(); // Prevent default form submission

        const formData = new FormData(event.target);
        
        try {
            const data = await saveContact(formData);  // Pass the key-value object
            console.log('Contact saved successfully:', data);

            // Show success notification
            showToast('Your message has been sent successfully!', 'success');
            event.target.reset();  // Reset form fields after submission

        } catch (error) {
            console.error('Error saving contact:', error);
            event.target.reset();
            // Show error notification
            showToast('There was an error sending your message. Please try again.', 'error');
        }
    };
    const showToast = (message, type) => {
        // Create the toast element
        const toast = document.createElement('div');
        toast.className = `toast-card ${type}`;
        toast.innerText = message;
    
        // Create the progress bar element
        const progressBar = document.createElement('div');
        progressBar.className = 'toast-progress';
    
        // Append the progress bar to the toast
        toast.appendChild(progressBar);
    
        // Add the toast to the body or container
        document.body.appendChild(toast);
    
        // Set the toast to disappear after 6 seconds
        setTimeout(() => {
            toast.classList.add('fade-out');  // Add a fade-out effect before removing it
            setTimeout(() => {
                toast.remove();  // Remove the toast after the fade-out effect
            }, 500);  // Time for the fade-out effect
        }, 5000);  // Duration of the toast display
    };
    

    return (
        <section className="contact" id="contact">
            <h2>Get in Touch</h2>
            <form className="contact-form" onSubmit={handleSubmitForm}>
                <input type="text" name="name" placeholder="Name" required />
                <input type="tel" placeholder="Phone" name='phone' required />
                <input type="email" placeholder="Email" name='email' />
                <textarea name="query" placeholder="Message"></textarea>
                <button className="contact-button" type="submit">Send Message</button>
            </form>

            <br />
            <div className="contact-details">
                <p>Phone: +91 8210760017</p>
                <p>Email: samridh@clinixsphere.in</p>
            </div>
        </section>
    );
}

export default Contact;
