import React, { useRef } from 'react';
import './popup.css'; // Import your updated CSS

const Popup = ({ isOpen, onClose, title, fields, requiredFields, onSubmit }) => {
  const formRef = useRef(null);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Close the popup if clicking outside the box
      resetForm(); // Reset the form on close
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(new FormData(formRef.current)); // Pass form data to the parent component
    resetForm(); // Reset the form on submit
    onClose(); // Close the popup on submit
  };

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.reset(); // Reset the form fields
    }
  };

  const handleValidation = (e, field) => {
    const input = e.target;
    // If there is a pattern and a custom error message, set custom validity
    if (field.pattern && input.validity.patternMismatch) {
      input.setCustomValidity(field.customErrorMessage || 'Invalid format');
    } else {
      input.setCustomValidity(''); // Clear custom error when valid
    }
  };

  return (
    <div className={`popup-overlay ${isOpen ? 'active' : ''}`} onClick={handleOverlayClick}>
      <div className="popup-box">
        <h2>{title}</h2>
        <form ref={formRef} onSubmit={handleSubmit}>
          {fields.map((field, index) => (
            <div key={index} className="form-group">
              <label htmlFor={field.name}>{field.label}</label>
              {field.type === 'textarea' ? (
                <textarea
                  id={field.name}
                  name={field.name}
                  rows="4"
                  required={requiredFields.includes(field.name)} // Add required attribute conditionally
                />
              ) : (
                <input
                  type={field.type}
                  id={field.name}
                  name={field.name}
                  required={requiredFields.includes(field.name)} // Add required attribute conditionally
                  pattern={field.pattern || undefined} // Add pattern if available
                  onInput={(e) => handleValidation(e, field)} // Custom validation handler
                />
              )}
            </div>
          ))}
          <div className="popup-actions">
            <button type="submit" className="submit-btn">Submit</button>
            <button type="button" className="cancel-btn" onClick={() => { resetForm(); onClose(); }}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Popup;
