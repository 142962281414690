import { formToJSON } from 'axios';
import axios from './axiosConfig'; // Import axios instance from axiosConfig.js

const version = process.env.REACT_APP_VERSION;

// Function to save contact details
export const saveContact = async (formData) => {
    let jsonData = formToJSON(formData);
    
    try {
        const response = await axios.post(`${version}/api/contact/save-contact`, jsonData);
        return response.data;
    } catch (error) {
        console.error('Error saving contact:', error);
        throw error;
    }
};
