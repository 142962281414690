import React from 'react';
import './pricing.css';

const Pricing = () => {
  return (
    <section className="pricing" id="pricing">
      <h2 className="section-title">Pricing Plans</h2><br />
      <div className="pricing-grid">
        <div className="pricing-card">
          <h3>Basic Plan</h3>
          <p className="price">₹4000/month</p>
          <ul>
            <li>Patient Management</li>
            <li>Priority Support</li>
          </ul>
          {/* <button className="sign-up-btn">Sign Up</button> */}
        </div>
        <div className="pricing-card">
          <h3>Standard Plan</h3>
          <p className="price">₹7000/month</p>
          <ul>
            <li>Patient Management</li>
            <li>Inventory Management</li>
            <li>Priority Support</li>
          </ul>
          {/* <button className="sign-up-btn">Sign Up</button> */}
        </div>
        <div className="pricing-card">
          <h3>Comprehensive Plan</h3>
          <p className="price">₹14000/month</p>
          <ul>
            <li>Patient Management</li>
            <li>Inventory Management</li>
            <li>Metrics and Reporting</li>
            <li>Priority Support</li>
          </ul>
          {/* <button className="sign-up-btn">Sign Up</button> */}
        </div>
      </div>
    </section>
  );
}

export default Pricing;
