import React, { useState,useEffect } from 'react';
import './aboutUs.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Importing FontAwesome icons

const AboutUs = () => {
  const [isModalOpen, setModalOpen] = useState(false);// State to manage modal visibility
  // Function to open the modal
  const handleViewMore = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  // Function to detect if the click is outside the modal and close it
  const handleOutsideClick = (e) => {
    // Close modal if click is outside the modal-content
    if (e.target.classList.contains('modal-overlay')) {
      closeModal();
    }
  };

  useEffect(() => {
        // When modal is open, disable background scrolling and add click event listener
    if (isModalOpen) {
      document.body.classList.add('no-scroll'); // Disable background scroll
      window.addEventListener('click', handleOutsideClick);
    } else {
      document.body.classList.remove('no-scroll'); // Enable background scroll
      window.removeEventListener('click', handleOutsideClick);
    }

    // Cleanup the scroll-lock when component unmounts
    return () => {
      document.body.classList.remove('no-scroll'); // In case modal is still open
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [isModalOpen]);// Effect runs whenever `isModalOpen` state changes


  return (
    <div className="about-us-container">
    <section className="about-us-section">
      <h1>About Us</h1>
      <p className="about-description">
        Clinix Sphere offers a platform that streamlines administrative processes, allowing healthcare providers to focus on delivering superior care. We provide customized backend solutions for clinics and hospitals.
      </p>
      <div className="view-more-wrapper centered">
      <button className="view-more-btn" onClick={handleViewMore}>
      View More
          <span className="circle">
            <i className="fa fa-arrow-right"></i>
          </span>
        </button>
      </div>
    </section>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <button className="close-btn" onClick={closeModal}>×</button>
            </div>    
            <div className="modal-body">
              <h3>About Clinix Sphere</h3>
              <p>
               Clinix Sphere is a healthcare technology leader, providing customized backend solutions for clinics and hospitals. Our platform enhances operational efficiency and patient care through tailored tools for patient management, telemedicine, inventory, and HR. 
              We offer each healthcare facility a dedicated domain, giving them full control over their digital operations, ensuring a seamless and personalized experience. By streamlining administrative processes, we empower healthcare providers to focus on delivering superior care and optimizing their operations.
            </p>

            <h5>Patient Interaction:</h5>
            <p>
              Patients interact with healthcare services provided through Clinix Sphere, but not with the platform directly. Instead, they receive seamless, branded communication from their healthcare facility via SMS, email, or WhatsApp. This ensures a consistent experience that feels connected to the clinic or hospital they are familiar with, rather than an external platform.
              Through these communication channels, patients can access critical services like appointment scheduling, telemedicine sessions, and lab result viewing, making it easier for them to manage their healthcare needs. For example, a patient may receive a link to schedule a follow-up appointment or join a virtual consultation, ensuring convenience and accessibility.
              Moreover, all communications carry the branding and identity of the healthcare provider, reinforcing a unified and professional experience. This white-label approach ensures that patients feel directly connected to their healthcare provider, building trust and ensuring a smooth, uninterrupted journey throughout their treatment or consultation process.
            </p>
            
            <h5>Healthcare Facility Interaction:</h5>
            <p>
              On the other side, healthcare facilities interact directly with the Clinix Sphere platform through a dedicated domain. This allows each clinic or hospital to have full ownership and control over its digital operations, creating a sense of autonomy in how they manage their services.
              The platform provides an extensive set of tools to streamline operations and enhance efficiency. Healthcare facilities can access features like appointment management, patient records, billing, inventory control, prescription management, and telemedicine – all through a single, user-friendly interface. These tools enable the healthcare provider to effectively manage both their day-to-day tasks and long-term operational needs, from overseeing patient care to ensuring compliance with regulatory standards.
              The interaction between Clinix Sphere and healthcare facilities is designed to optimize workflow, improve communication, and reduce administrative burdens. Facilities benefit from a customizable platform that scales with their needs, ensuring they can maintain focus on delivering high-quality patient care while Clinix Sphere handles the backend complexities.
            </p>
            
            <p><strong>This dual interaction model</strong> separates patient and facility-facing experiences, which is a key aspect of what makes Clinix Sphere’s solution unique. It allows patients to interact with their healthcare provider seamlessly, while giving facilities the autonomy and technological support needed to improve care delivery and operational performance.</p>
          </div>
          </div>
        </div>
      )}

      <section className="interactive-models-section">
        <h2>Interactive Models</h2>
        <hr className="line-below-heading" />

        <div className="interaction-model">
          <div className="interaction-content">
            <h3>Patient Interaction:</h3>
            <hr className="line-below-subheading" />
            <ul>
              <li>Communications are branded under the healthcare provider, ensuring a seamless, familiar experience.</li>
              <li>Patients interact with services provided by their healthcare facility through Clinix Sphere, but not the platform itself.</li>
            </ul>
          </div>
          <div className="interaction-icon-wrapper">
            <div className="interaction-icon">
              <i className="fa fa-heartbeat icon"></i> {/* Update icon if necessary */}
            </div>
            <div className="view-more-wrapper">
              <button className="view-more-btn" onClick={handleViewMore}>
                View More
                <span className="circle">
                  <i className="fa fa-arrow-right"></i>
                </span>
              </button>
            </div>
          </div>
        </div>

        <hr className="divider" />

        <div className="interaction-model">
          <div className="interaction-content">
            <h3>Healthcare Interaction:</h3>
            <hr className="line-below-subheading" />
            <ul>
              <li>Facilities access Clinix Sphere through a dedicated domain, allowing full control over digital operations.</li>
              <li>Key tools include appointment management, patient records, billing, inventory, prescription management, and telemedicine.</li>
            </ul>
          </div>
          <div className="interaction-icon-wrapper">
            <div className="interaction-icon">
              <i className="fa fa-hospital icon"></i> {/* Update icon if necessary */}
            </div>
            <div className="view-more-wrapper">
              <button className="view-more-btn" onClick={handleViewMore}>
                View More
                <span className="circle">
                  <i className="fa fa-arrow-right"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default AboutUs;
